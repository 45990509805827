import { createRouter, createWebHistory } from 'vue-router'
function loadPages(page) {
  return () => import(/* webpackChunkName: "view-[request]" */ `../views/${page}`)
}
// 公共路由
export const constantRoutes = [
  {
    path: '',
    name: 'index',
    component: loadPages('Index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: loadPages('Login.vue')
  },
  {
    path: '/score',
    name: 'score',
    component: loadPages('Score.vue')
  },
  {
    path: '/score-form',
    name: 'score-form',
    component: loadPages('ScoreForm.vue')
  },
  // {
  //   path: '/',
  //   name: 'index',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: loadPages('Index.vue')
  // }
]

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = [

]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

export default router
